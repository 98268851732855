.lyt1-header {
  height: 60px;
  background-color: white;
  box-shadow: 0 2px 4px #0000001c;
  z-index: 99999;
}

.lyt1-breadcrumbs {
  height: 50px;
  width: 100%;
  display: flex;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
}

.lyt1-main {
  height: calc(100vh - 110px);
  justify-content: center;
  display: flex;
  overflow-y: auto;
  padding: 10px;
  box-sizing: border-box;
}