* {
  box-sizing: border-box;
}

.lyt2-header {
  height: 60px;
  background-color: white;
  box-shadow: 0 2px 4px #0000001c;
  z-index: 99999;
}

.lyt2-main {
  height: calc(100vh - 60px);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 10px;
}

.lyt2-breadcrumbs {
  height: 30px;
  width: 100%;
  display: flex;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
}

.lyt2-top-bar {
  height: 50px;
  width: 100%;
}

.lyt2-footer-bar {
  height: 0px;
  width: 100%;
}

.lyt2-main-content {
  height: calc(100% - 80px);
  width: 100%;
  overflow: auto;
  display: flex;
}

.lyt2-main-left {
  width: 180px;
  height: 100%;
  padding: 10px;
}

.lyt2-main-center {
  flex: 1;
  height: 100%;
  padding: 10px;
}

.lyt2-main-right {
  width: 420px;
  min-width: 360px; /* Not allow to squeeze */
  height: 100%;
  padding: 10px;
}

.lyt2-main-left-inner {
  height: 100%;
  overflow-y: auto;
  box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  background-color: white;
}

.lyt2-main-center-inner {
  height: 100%;
  overflow-y: auto;
  box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  background-color: white;
}

.lyt2-main-right-inner {
  height: 100%;
  overflow-y: auto;
  box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  background-color: white;
}