.topBar {
  height: 80px;
  padding: 20px;
  display: flex;
  align-items: flex-start;
}

.chart {
  height: calc(100% - 80px);
}

.readingCount {
  position: absolute;
  left: 20px;
  bottom: 16px;
}

@media (max-width: 1500px) {
  .topBar{
    height: 130px;
  }

  .chart {
    height: calc(100% - 130px) !important;
  }
}